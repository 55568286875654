import React from 'react';
import {ReactComponent as BG} from '../assets/images/pages/about/bg.svg';
import {GatsbyImage} from 'gatsby-plugin-image';
import {useTranslation} from 'gatsby-plugin-react-i18next'
import {graphql} from "gatsby";
import '../assets/styles/pages/about.scss';

import Layout from '../components/layout';
import SEO from "../components/seo";

const About = (props) => {
    const {t} = useTranslation();
    console.log(props);

    return (
        <Layout pageID={'about'}>
            <SEO title="About" />
            <div className="bg-wrapper">
                <BG style={{fill: 'green'}}/>
            </div>
            <section className="top">
                <h1>{t("about")}</h1>
            </section>
            <section className="biography">
                <p>{t("biography")}</p>
                <div className="img-wrapper" style={{display: 'flex', justifyContent: 'center'}}>
                <GatsbyImage image={props.data.pic.childImageSharp.gatsbyImageData} alt={'Yonah and Jacob'}/>
                </div>
            </section>
        </Layout>
    )
}

export default About;

export const query = graphql`
  query ($language: String!) {
  pic: file(relativePath: {eq: "pages/about/Yonah and Jacob - 2022.jpg"}) {
    childImageSharp {
      gatsbyImageData(width: 400, placeholder: BLURRED, formats: [AUTO, WEBP], layout: CONSTRAINED, quality: 95)
    }
  }
    locales: allLocale(filter: {ns: {in: ["common", "about"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
